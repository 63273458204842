<template>
  <div class="ccont">
    <img src="../../../assets/images_app/img7.png" alt />
    <div class="ccont__cont">
      <div class="title">
        <h4>联系我们</h4>
        <hr class="white-line" />
        <small>contact us</small>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.ccont {
  position: relative;
  & > img {
    display: block;
    width: 100%;
  }

  &__cont {
    position: absolute;
    left: 0;
    top: 0;
    padding: 40px 15px;
    .title {
      padding-bottom: 10px;
      hr {
        margin-top: 14px;
        margin-bottom: 6px;
        width: 70px;
      }

      h4 {
        margin: 0;
        font-size: 28px;
        font-weight: normal;
      }
      small {
        font-size: 14px;
        color: #bdb9b3;
      }
    }

    @media (min-width: 768px) {
      padding: 80px 30px;

      .title {
        padding-bottom: 20px;
        hr {
          margin-top: 28px;
          margin-bottom: 12px;
          width: 140px;
        }
        h4 {
          font-size: 56px;
        }
        small {
          font-size: 28px;
        }
      }
    }
  }
}
</style>