<template>
  <div class="cbtm">
    <div class="cbtm__items">
      <p>商务合作</p>
      <p>bd@mixdo.net</p>
    </div>
    <hr class="white-line" />
    <div class="cbtm__items">
      <p>简历投递</p>
      <p>hr@mixdo.net</p>
      <div class="icons">
        <span>
          <img src="../../../assets/images_app/icon7.png" alt />
        </span>
        <span>
          <img src="../../../assets/images_app/icon8.png" alt />
        </span>
        <span>
          <img src="../../../assets/images_app/icon9.png" alt />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.cbtm {
  padding: 5px 15px 0;
  background: $red;

  &__items {
    padding-top: 27px;
    padding-bottom: 35px;

    p {
      margin: 0;
      &:first-child {
        font-size: 20px;
      }
      &:nth-child(2) {
        margin-top: 12px;
        font-size: 28px;
      }
    }
    .icons {
      display: flex;
      padding-top: 20px;
      padding-left: 3px;

      span {
        display: inline-block;
        img {
          width: 20px;
          -webkit-user-drag: none;
        }
        & + span {
          padding-left: 15px;
        }
      }
    }
  }
  hr {
    margin: 0;
    width: 270px;
    opacity: 0.4;
  }

  @media (min-width: 768px) {
    padding: 10px 30px 0;

    &__items {
      padding-top: 54px;
      padding-bottom: 70px;

      p {
        &:first-child {
          font-size: 40px;
        }
        &:nth-child(2) {
          margin-top: 24px;
          font-size: 56px;
        }
      }

      .icons {
        padding-top: 40px;
        padding-left: 6px;

        span {
          img {
            width: 34px;
          }
          & + span {
            padding-left: 30px;
          }
        }
      }
    }
    hr {
      width: 540px;
    }
  }
}
</style>