<template>
  <div class="contact">
    <ccont></ccont>
    <cbtm></cbtm>
    <hfooter></hfooter>
  </div>
</template>

<script>
import ccont from "./ccont.vue";
import cbtm from "./cbtm.vue";
import { hfooter } from "@/components/app/footer";
export default {
  components: { ccont, cbtm, hfooter },
};
</script>

<style lang="scss" scoped>
</style>